<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="6">
        <v-radio-group
          v-model="option"
          @change="option_change"
          row
          label="Affichage Par"
          mandatory
          dense
        >
          <v-radio label="Dossiers" value="1"></v-radio>
          <v-radio label="Factures" value="2"></v-radio>
          <v-radio label="Produits" value="3"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" v-show="!edition && option == '1'">
        <listitemspagecard
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'imports'"
          :AllResponse="'allimports'"
          :headers="headers"
          :field_list="['DATE_DOSSIER', 'NODOSSIER']"
          :filename="'Importations'"
          :sheetname="'Importations'"
          @open="NewDossierForm"
          @rowselect="DossierChange"
          :showedit="false"
          :key="klist"
          :Add="editer"
          :search_elm="filtre"
        >
        </listitemspagecard>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-show="!edition && option != '1'">
        <component
          :is="!edition && option != '1' ? 'listitemspage' : null"
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="option == '2' ? 'imports_facts' : 'imports_products'"
          :AllResponse="
            option == '2' ? 'allimports_facts' : 'allimports_products'
          "
          :headers="option == '2' ? facturesheaders : productsheaders"
          :field="option == '2' ? 'DATE_FACTURE' : 'CODE'"
          :filename="option == '2' ? 'Factures' : 'Produits'"
          :sheetname="option == '2' ? 'Factures' : 'Produits'"
          :showedit="false"
          @open="DossierSelect"
          :key="klist"
          :Add="false"
          :del="false"
        >
        </component>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
      <v-col cols="12" sm="6" md="8">
        <dossierprint
          :key="kprint"
          :dossier="dossier"
          :editer="editer"
          :valider="valider"
          :agences_list="agences_list"
          :tiers_agences="tiers_agences"
          :approches_list="approches_list"
          :countrys="countrys"
          :ports="ports"
          :tier_list="tier_list"
          :tab1="parseInt(tab1)"
          @cancel="dossier = selitem"
          @change="DossierChange"
          :edition="edition"
          :data_loading="data_load"
          @edition="edit_change"
          @tab_change="tab_change"
        >
        </dossierprint>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="edition"> </v-col>
    </v-row>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import DOSSIERS_LIST from "../graphql/Import/DOSSIERS_LIST.gql";
import FACTURES_LIST from "../graphql/Import/FACTURES_LIST.gql";
import PRODUCTS_LIST from "../graphql/Import/PRODUCTS_LIST.gql";
import DOSSIERS from "../graphql/Import/DOSSIERS.gql";
import ALLDOSSIERS from "../graphql/Import/ALLDOSSIERS.gql";
import ALLFACTURES from "../graphql/Import/ALLFACTURES_LIST.gql";
import ALLPRODUCTS from "../graphql/Import/ALLPRODUCTS_LIST.gql";
import DELETE_DOSSIER from "../graphql/Import/DELETE_DOSSIER.gql";
import DATA from "../graphql/Import/IMPORTS_DATA.gql";
import ALLTIERS from "../graphql/Commande/ALLTIERS.gql";
import UPDATE_DOSSIER from "../graphql/Import/UPDATE_DOSSIER.gql";

export default {
  components: {
    listitemspagecard: () => import("../components/ListItemsPagesCard.vue"),
    listitemspage: () => import("../components/ListItemsPages.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    dossierprint: () => import("../components/DossierPrint.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    filtre: "",
    editer: false,
    solder: false,
    valider: false,
    klist: 100,
    drawer: null,
    selitem: {},
    edition: false,
    data_load: false,
    tab1: null,
    headers: [
      {
        text: "",
        value: "nodossier",
        enum: "NODOSSIER",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "",
        value: "nocmd",
        enum: "NOCMD",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Ref.",
        value: "cmd_ref",
        enum: "CMD_REF",
        title: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "Date",
        value: "date_dossier",
        enum: "DATE_DOSSIER",
        slot: "date",
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "",
        align: "text-left",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
        sm: 6,
        md: 6,
      },

      {
        text: "Montant : ",
        value: "montant",
        enum: "MONTANT",
        align: "end",
        slot: "cur",
        selected: true,
        sm: 6,
        md: 6,
      },
      {
        text: "Statut.",
        value: "statut_id",
        selected: false,
        hiden: true,
      },

      {
        text: "",
        value: "status_name",
        enum: "STATUS_NAME",
        align: "text-center",
        expire: true,
        selected: true,
        sm: 4,
        md: 4,
      },
      {
        text: "",
        value: "status_bank",
        enum: "STATUS_BANK",
        align: "text-center",
        expire: true,
        selected: true,
        sm: 6,
        md: 6,
      },
      {
        text: "",
        value: "status_fret",
        enum: "STATUS_FRET",
        align: "text-center",
        expire: true,
        selected: true,
        sm: 6,
        md: 6,
      },
    ],
    facturesheaders: [
      {
        text: "No",
        value: "no",
        align: "center",
        selected: true,
      },
      {
        text: "N° Facture",
        value: "num_facture",
        enum: "NUM_FACTURE",
        selected: true,
      },
      {
        text: "Date facture",
        value: "date_facture",
        slot: "date",
        enum: "DATE_FACTURE",
        selected: true,
      },
      {
        text: "N° Dossier",
        value: "nodossier",
        slot: "href",
        enum: "NODOSSIER",
        selected: true,
      },
      {
        text: "Date dossier",
        value: "date_dossier",
        slot: "date",
        enum: "DATE_DOSSIER",
        selected: true,
      },
      {
        text: "Code",
        value: "code_fournisseur",
        enum: "CODE_FOURNISSEUR",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        enum: "NOCMD",
        selected: false,
      },

      {
        text: "Montant Facture",
        value: "montant",
        enum: "MONTANT",
        slot: "cur",
        align: "end",
        width: "120px",
        selected: true,
      },
      {
        text: "N° SWIFT",
        value: "num_paiement",
        enum: "NUM_PAIEMENT",
        selected: true,
      },
      {
        text: "Date SWIFT",
        value: "date_paiement",
        slot: "date",
        enum: "DATE_PAIEMENT",
        selected: true,
      },

      {
        text: "Statut",
        value: "statut_name",
        enum: "STATUT_NAME",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        enum: "COMMENT_FACTURE",
        selected: false,
      },
    ],
    productsheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        enum: "CODE",
        selected: true,
        align: "left",
        width: "140px",
      },
      {
        text: "Produit",
        value: "label",
        enum: "LABEL",
        selected: true,
        align: "left",
      },
      {
        text: "N° Dossier",
        value: "nodossier",
        slot: "href",
        enum: "NODOSSIER",
        selected: true,
      },
      {
        text: "Date dossier",
        value: "date_dossier",
        slot: "date",
        enum: "DATE_DOSSIER",
        selected: true,
      },
      {
        text: "Code",
        value: "code_fournisseur",
        enum: "CODE_FOURNISSEUR",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        enum: "TIER_NAME",
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        enum: "NOCMD",
        selected: true,
      },

      {
        text: "PU",
        value: "pu",
        enum: "PU",
        width: "100px",
        selected: true,
      },

      {
        text: "Quantité",
        value: "qte",
        enum: "QTE",
        width: "120px",
        slot: "cur",
        align: "right",
        selected: true,
      },
      {
        text: "Montant",
        value: "montant",
        enum: "MONTANT",
        slot: "cur",
        align: "end",
        width: "180px",
        selected: true,
      },

      {
        text: "Comment",
        value: "comment",
        enum: "COMMENT_PRODUCT",
        selected: true,
      },
    ],
    option: 1,
    agences_list: [],
    tiers_agences: [],
    approches_list: [],
    countrys: [],
    ports: [],
    tier_list: [],
    dossier: {},
    kprint: 100,
    loading: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    Qselect() {
      return this.option == "1"
        ? DOSSIERS_LIST
        : this.option == "2"
        ? FACTURES_LIST
        : PRODUCTS_LIST;
    },
    Qselectall() {
      return this.option == "1"
        ? ALLDOSSIERS
        : this.option == "2"
        ? ALLFACTURES
        : ALLPRODUCTS;
    },
    Qdelete() {
      return DELETE_DOSSIER;
    },
  },
  watch: {},
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },
  async beforeRouteLeave(to, from, next) {
    if (this.$store.state.isEditing) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Quitter sans enregistrement !! Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        await this.update_modif();
        next();
      } else return;
    } else next();
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
  },

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02069") || this.$store.state.isadmin;

    this.valider =
      this.$store.state.auth.includes("03069") || this.$store.state.isadmin;

    await this.get_data();

    if (this.$route.params.cmd_id) {
      this.dossier.id = -1;
      this.dossier.cmd_id = this.$route.params.cmd_id;
      this.edition = true;
      this.kprint++;
    }
  },

  methods: {
    tab_change(tab) {
      this.tab1 = tab;
    },
    async preventNav(event) {
      if (!this.$store.state.isEditing) return;
      event.preventDefault();
      event.stopImmediatePropagation();

      //event.returnValue = "";
      await this.update_modif();
      //
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.dossier.id > 0) {
        let v = {
          dossierimport: {
            id: this.dossier.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_DOSSIER, v);
      }
    },

    edit_change(value) {
      this.edition = value;
    },
    async maj(query, v) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },

    async get_data() {
      let r = await this.requette(DATA);
      if (r) {
        this.agences_list = r.agences;
        this.tiers_agences = r.tiers_agences;
        this.approches_list = r.approches;
        this.ports = r.ports;
        this.countrys = r.countrys;
      }

      let v = {
        where: {
          AND: [
            {
              column: "TIER_TYPE_ID",
              operator: "IN",
              value: ["7", "8"],
            },
            {
              column: "CODE_FOURNISSEUR",
              operator: "IS_NOT_NULL",
            },
          ],
        },
        statut_id: [2, 3],
      };
      r = await this.requette(ALLTIERS, v);
      if (r) {
        this.tier_list = r.alltiers;
      }
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    option_change() {
      this.$nextTick(() => {
        this.dossier = {};
        this.kprint++;
      });
      this.klist++;
    },
    DossierSelect(item) {
      if (item.id) {
        this.option = "1";
        this.filtre = item.nodossier;
        this.klist++;
      }
    },
    async DossierChange(item) {
      if (item) {
        if (item.id) {
          this.data_load = true;
          this.selitem = item;
          let r = await this.requette(
            DOSSIERS,
            {
              where: {
                column: "ID",
                value: item.id,
              },
            },
            "no-cache"
          );
          if (r) {
            this.dossier = r.imports.data[0];
            this.kf++;
            this.data_load = false;
          }
        } else {
          this.mvme = {};
          this.selitem = item;
          this.keymvm++;
        }
      }
      this.kprint++;

      this.filtre = null;
    },
    NewDossierForm(item) {
      this.edition = true;
      this.kprint++;
      this.dossier = item;
    },
  },
};
</script>
